import s3url from "common/src/lib/image/s3url"
import getBackgroundSize from "./getBackgroundSize"

const positions = {
    "l": "left",
    "r": "right",
    "c": "center",
    "t": "top",
    "b": "bottom"
};


export default function getGridStyle(layout = {}, opt = {}) {
    let { img, template, imgSize = {}, bigSlot = false } = opt;
    let gridStyle = {},
        backgroundStyle = {},
        v = layout.v || "c",
        h = layout.h || "c",
        x = positions[h],
        y = positions[v],
        imgSizeBig = imgSize.big || 500,
        imgSizeNormal = imgSize.normal || null,
        backSize = img ? getBackgroundSize(layout, img, template) : null,
        src = img ? 
                typeof img === "string" ? 
                    img :
                    (img.bgPreview ? 
                        `data:image/png;base64,${img.bgPreview}` :
                        s3url(img.key || img.src, bigSlot ? imgSizeBig : imgSizeNormal)) : 
                null;

    if (backSize) {
        backSize += "%";
    }

    if (img && img.horizontal) {
        backSize = "auto " + backSize;
    } 

    if (v !== "c" && layout.vplus !== 0) {
        y += " " + layout.vplus + "%";
    }
    if (h !== "c" && layout.hplus !== 0) {
        x += " " + layout.hplus + "%";
    }

    gridStyle["gridRow"] = "span " + layout.row;
    gridStyle["gridColumn"] = "span " + layout.col;

    if (backSize) {
        backgroundStyle["backgroundSize"] = backSize;
        backgroundStyle["backgroundRepeat"] = "no-repeat";
        backgroundStyle["backgroundImage"] = "url(" + src + ")";
        backgroundStyle["backgroundPosition"] = x + " " + y;
    }

    if (layout.bg) {
        backgroundStyle['backgroundColor'] = layout.bgColor;
    }

    return { ...gridStyle, ...backgroundStyle };
}