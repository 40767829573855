import React, { useEffect, useMemo } from 'react';
import { Amplify } from '@aws-amplify/core'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import PasswordDialog from "common/src/components/user/Password"
import CypressHistorySupport from "common/src/components/CypressHistorySupport"
import { CrossPlatformProvider } from "common/src/cross-platform/Provider"
import GlobalOverlay from "common/src/components/dialog/Overlay"
import AppDomHelper from "common/src/components/AppDomHelper"
import Loader from "common/src/components/Loader"
import Alert from "common/src/components/dialog/Alert"
import Footer from "common/src/components/admin-layout/Footer"
import SigninHeader from "common/src/components/admin-layout/SigninHeader"

import TopMenu from "./components/TopMenu"
import PageProfile from "./components/pages/Profile"
import PageContacts from "./components/pages/Contacts"
import PageHome from "./components/pages/Home"
import PageSignin from "./components/pages/SignIn"
import PageRegister from "./components/pages/SignUp"
import PagePeople from "./components/pages/People"
import PageInvitations from "./components/pages/Invitations"
import PagePSEApplications from "./components/pages/PSEApplications"
import PageVisitorApplications from "./components/pages/VisitorApplications"
import PageHistory from "./components/pages/SearchHistory"
import PageTags from "./components/pages/Tags"
import PageSearch from "./components/pages/Search"
//import PageClicks from "./components/pages/Clicks"
import PageSynonyms from "./components/pages/Synonyms"


import PageCatSearch from "./components/pages/CatalogueSearch"
import PageCatFiles from "./components/pages/CatalogueFiles"
import PageCatCategories from "./components/pages/catalogue/Categories"
import PageCatCategoryQueue from "./components/pages/catalogue/CategoryQueue"
import PageCatColors from "./components/pages/catalogue/Colors"
import PageCatColorQueue from "./components/pages/catalogue/ColorQueue"
import PageCatMaterials from "./components/pages/catalogue/Materials"
import PageCatMaterialQueue from "./components/pages/catalogue/MaterialQueue"
import PageCatCharacteristics from "./components/pages/catalogue/Characteristics"
import PageCatCharacteristicQueue from "./components/pages/catalogue/CharacteristicQueue"
import PageCatIgnored from "./components/pages/catalogue/Ignored"
import PageProducts from "./components/pages/catalogue/Products"
import PageCatDesignerQueue from "./components/pages/catalogue/DesignerQueue"
import PageCatDesigners from "./components/pages/catalogue/Designers"
import PageCatDesignersTop from "./components/pages/catalogue/DesignerTop"
import PageCatDesignersExclusion from "./components/pages/catalogue/DesignerExclusion"

import PageCommissions from './components/pages/reports/Commissions'
import PageClicks from './components/pages/reports/Clicks'
import PageFris from './components/pages/reports/FRI'
import PageLinks from "./components/pages/reports/Links"
import PageRawReport from "./components/pages/reports/Raw"
import PagePseReport from "./components/pages/reports/PSE"
import PagePseAggReport from "./components/pages/reports/PSEAggregation"

import PageAccCommissions from "./components/pages/accounting/Commissions"
import PageAccSettings from "./components/pages/accounting/PaymentSettings"
import PageAccPayments from "./components/pages/accounting/Payments"

import PageChats from "./components/pages/tools/Chats"
import PageConnections from "./components/pages/tools/Connections"
import PageRevolut from "./components/pages/tools/Revolut"
import PageTiming from "./components/pages/tools/Timing"
import PageRemoveBgBalance from "./components/pages/tools/RemovebgBalance"

import awsconfig from 'common/src/awsConfig'
import * as userActions from "common/src/actions/user"
import routes from "./routes"
import commonListeners from "common/src/listeners"
import catalogueListeners from "common/src/listeners/catalogue"
import provideComponent from "common/src/cross-platform/provideComponent"
import listeners from "./listeners"
import logger from "common/src/logger"
import user from "common/src/user"
import api from "app/api"
import hub from "common/src/hub"


hub.registerProvider(provideComponent);
Amplify.configure({ ...awsconfig, Analytics: { disabled: true } });
logger.init("/" + process.env.REACT_APP_ENV + "/tf-admin/app");
commonListeners.init();
listeners.init();
catalogueListeners.init();
api.init();


const pages = [
    {
        route: routes.home,
        component: PageHome,
        accessKey: "admin/dashboard"
    },
    {
        route: routes.profile,
        component: PageProfile
    },
    {
        route: routes.profileContacts,
        component: PageContacts
    },
    {
        route: routes.people,
        component: PagePeople,
        accessKey: "admin/people"
    },
    {
        route: routes.invitations,
        component: PageInvitations,
        accessKey: "admin/people"
    },
    {
        route: routes.pseapplications,
        component: PagePSEApplications,
        accessKey: "admin/people"
    },
    {
        route: routes.visitorapplications,
        component: PageVisitorApplications,
        accessKey: "admin/people"
    },
    {
        route: routes.tags,
        component: PageTags,
        accessKey: "admin/tags"
    },
    {
        route: routes.searchHistory,
        component: PageHistory,
        accessKey: "admin/search"
    },
    {
        route: routes.search,
        component: PageSearch,
        accessKey: "admin/search"
    },
    {
        route: routes.searchSynonyms,
        component: PageSynonyms,
        accessKey: "admin/search"
    },

    {
        route: routes.catalogueSearch,
        component: PageCatSearch,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueFiles,
        component: PageCatFiles,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueCategories,
        component: PageCatCategories,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueCategoryQueue,
        component: PageCatCategoryQueue,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueColors,
        component: PageCatColors,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueColorQueue,
        component: PageCatColorQueue,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueMaterials,
        component: PageCatMaterials,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueMaterialQueue,
        component: PageCatMaterialQueue,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueCharacteristics,
        component: PageCatCharacteristics,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueCharacteristicQueue,
        component: PageCatCharacteristicQueue,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueIgnored,
        component: PageCatIgnored,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.products,
        component: PageProducts,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueDesignerQueue,
        component: PageCatDesignerQueue,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueDesigners,
        component: PageCatDesigners,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueDesignersTop,
        component: PageCatDesignersTop,
        accessKey: "admin/catalogue"
    },
    {
        route: routes.catalogueDesignersExclusion,
        component: PageCatDesignersExclusion,
        accessKey: "admin/catalogue"
    },

    {
        route: routes.reportCommissions,
        component: PageCommissions,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportClicks,
        component: PageClicks,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportFris,
        component: PageFris,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportLinks,
        component: PageLinks,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportRaw,
        component: PageRawReport,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportPse,
        component: PagePseReport,
        accessKey: "admin/analytics"
    },
    {
        route: routes.reportPseAgg,
        component: PagePseAggReport,
        accessKey: "admin/analytics"
    },

    {
        route: routes.accountingCommissions,
        component: PageAccCommissions,
        accessKey: "admin/accounting"
    },
    {
        route: routes.accountingSettings,
        component: PageAccSettings,
        accessKey: "admin/accounting"
    },
    {
        route: routes.accountingPayments,
        component: PageAccPayments,
        accessKey: "admin/accounting"
    },

    {
        route: routes.toolsChats,
        component: PageChats,
        accessKey: "admin/tools/chats"
    },
    {
        route: routes.toolsConnections,
        component: PageConnections,
        accessKey: "admin/tools/connections"
    },
    {
        route: routes.toolsRevolut,
        component: PageRevolut,
        accessKey: "admin/tools/revolut"
    },
    {
        route: routes.toolsTiming,
        component: PageTiming,
        accessKey: "admin/tools/timing"
    },
    {
        route: routes.toolsRemovebg,
        component: PageRemoveBgBalance,
        accessKey: "admin/tools/removebg"
    }
];



const nliPages = [
    {
        route: routes.signin,
        component: PageSignin
    },
    {
        route: routes.register,
        component: PageRegister
    },
];


function App() {

    const [ current, loggedIn, checking ] = useSelector(s => ([
        s.user.current,
        s.user.loggedIn,
        s.user.ui.checking
    ]));
    const queryClient = useMemo(() => new QueryClient(), []);
    const defaultRoute = useMemo(
        () => (loggedIn && current) && pages.find(p => !p.accessKey || user.hasAccessTo(p.accessKey)),
        [ loggedIn, current ]
    );

    useEffect(
        () => {
            userActions.checkCurrent().catch(err => console.log(err));
        },
        []
    );

    if (loggedIn === null || (loggedIn === true && !current)) {
        return (
            <div className="app-loading"><Loader size={64} /></div>
        )
    }

    return (
        <QueryClientProvider client={queryClient}>
        <Router>
            <CypressHistorySupport/>
            <AppDomHelper />
            <CrossPlatformProvider>
                {checking ?
                    <div className="app-loading"><Loader size={64} /></div> :
                    loggedIn ?
                        <>
                            <TopMenu/>
                            <Switch>

                                { pages.map((p,inx) => {
                                    if (!p.accessKey || user.hasAccessTo(p.accessKey))  {
                                        return <Route 
                                                key={ inx }
                                                exact 
                                                path={ p.route } 
                                                component={ p.component }/>
                                    }
                                    return null;
                                })}

                                { defaultRoute && <Redirect from="*" to={ defaultRoute.route }/> }
                            </Switch>
                        </> :
                        <>
                            <SigninHeader/>
                            <Switch>
                            { nliPages.map((p,inx) => 
                                <Route 
                                    key={ inx }
                                    exact 
                                    path={ p.route } 
                                    component={ p.component }/>
                            )}
                            <Redirect from="*" to={ routes.signin }/>
                        </Switch>
                        </>
                }
            </CrossPlatformProvider>
            <Footer />
            <PasswordDialog/>
            <GlobalOverlay />
            <Alert />
        </Router>
        </QueryClientProvider>
    );
}

export default App