import { batch } from "react-redux"

import api from "app/api"
import store from "app/store"
import { data, ui, sets } from "common/src/store/catalogue"
import hub from "common/src/hub"
import user from "common/src/user"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import getExchangeRates from "common/src/lib/getExchangeRates"
import calculateCommissionRate from "common/src/lib/catalogue/calculateCommissionRate"
import getRateForRetailer from "common/src/lib/catalogue/getRateForRetailer"
import { getRetailers } from "common/src/lib/catalogue/retailers"


function getCommissionRates() {
    const state = store.getState();
    return state.catalogue?.data?.commissionRates || null;
}

export const loadColors = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.colors.length > 0) {
        return state.catalogue.data.colors;
    }

    const order = { name: "asc" };
    const colors = await api.catalogueColor.list({ order });
    store.dispatch(data.colors.set(colors));
    return colors;
}

export const loadMaterials = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.materials.length > 0) {
        return state.catalogue.data.materials;
    }

    const order = { name: "asc" };
    const materials = await api.catalogueMaterial.list({ order });
    store.dispatch(data.materials.set(materials));
    return materials;
}


export const loadCharacteristics = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.characteristics.length > 0) {
        return state.catalogue.data.characteristics;
    }

    const order = { name: "asc" };
    const characteristics = await api.catalogueCharacteristic.list({ order });
    store.dispatch(data.characteristics.set(characteristics));
    return characteristics;
}
/*
const prepareCategoriesTree = (list) => {
    const categories = [];
    const map = {};

    list.forEach(c => {
        map[c.id] = { ...c };
        map[c.id].children = [];

        if (c.level === 0) {
            categories.push(map[c.id]);
        }
        else if (map[ c.parentId ]) {
            map[ c.parentId ].children.push(map[ c.id ]);
            map[ c.id ].parent = map[ c.parentId ];
        }
    });

    return categories;
}*/



export const loadCategories = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.categories.length > 0) {
        return state.catalogue.data.categories;
    }

    const order = { treePosition: "asc" };
    const categories = await api.catalogueCategory
                                .list({ order });
                                //.then(list => prepareCategoriesTree(list));
    store.dispatch(data.categories.set(categories));
    return categories;
}

export const saveProduct = async (productCatalogueId) => {
    if (!user.loggedIn()) {
        hub.dispatch("app", "redir", getUrl(routes.signin));
        return;
    }
    const userId = user.id();
    return api.userSavedCatalogueProduct
            .create({ userId, productCatalogueId }, "returning { userId }")
            .then(() => {
                hub.dispatch("catalogue", "product-saved", productCatalogueId);
            })
            .catch(err => {
                hub.dispatch("error", "catalogue-product-save", err);
            });
}

export const unsaveProduct = async (productCatalogueId) => {
    if (!user.loggedIn()) {
        hub.dispatch("app", "redir", getUrl(routes.signin));
        return;
    }
    const userId = user.id();
    return api.userSavedCatalogueProduct.remove({
        userId: { _eq: userId },
        productCatalogueId: { _eq: productCatalogueId }
    })
    .then(() => {
        hub.dispatch("catalogue", "product-unsaved", productCatalogueId);
    })
    .catch(err => {
        hub.dispatch("error", "look-unsave", err);
    });
}




export const catalogueEuroCodes = "AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,"+
                        "GR,HU,IE,IT,LV,LT,LU,MT,NL,PL," +
                        "PT,RO,SK,SI,ES,SE,AL,AD,AM,BY,BA,FO,GE,GI,IS,IM,XK,LI,MK,MD," +
                        "MC,ME,NO,RU,SM,RS,CH,TR,UA,VA";

export const catalogueRestCodes = "!AT,!BE,!BG,!HR,!CY,!CZ,!DK,!EE,!FI,!FR,!DE,!GR,!HU,"+
                    "!IE,!IT,!LV,!LT,!LU,!MT,!NL,!PL," +
                    "!PT,!RO,!SK,!SI,!ES,!SE,!AL,!AD,!AM,!BY,!BA,!FO,!GE,!GI,!IS,!IM,!XK,!LI,!MK,!MD," +
                    "!MC,!ME,!NO,!RU,!SM,!RS,!CH,!TR,!UA,!VA,!GB,!US";

export const catalogueRestCurrencies = "!USD,!GBP,!EUR";

export const catalogueFilterKeys = [
    "start", "limit", "query", "stage", "region", "retailer", "brand", "gender", "region",
    "category_id", "color_id", "material_id", "characteristic_id",
    "feed_category_id", "feed_color_id", "feed_material_id",
    "product_web_id", "currency", "price", "designer_id",
    "sort_by", "sort_dir", "id", "catalogue_source", "skip_backend_cache"
];


export function convertCurrency(product, convertTo, exchangeRates) {

    if (product.currency.toLowerCase() !== convertTo.toLowerCase()) {
        if (!product.original_price) {
            product.original_price = {
                price: product.price,
                sale_price: product.sale_price,
                currency: product.currency
            };
        }
        const rate = exchangeRates[ product.original_price.currency.toLowerCase() ][ convertTo.toLowerCase() ];
        product.currency_converted = true;
        product.price = product.original_price.price * rate;
        product.sale_price = product.original_price.sale_price * rate;
        product.currency = convertTo.toUpperCase();
    }
    
    return product;
}

function applyCommissionRates(product, commissionRates) {

    const rate = calculateCommissionRate(
        product.catalogue_source,
        product.retailer,
        product.region,
        commissionRates
    );

    product.commissionRate = rate;
    return product;
}

export const PER_PAGE = 50;



const filterValue = (v) => {
    if (typeof v === "number") {
        return v;
    }
    if (!v) {
        return null;
    }
    if (v && (Array.isArray(v) || typeof (v) === "object") && "length" in v) {
        return [ ...v ].sort().join(",");
    }
    return v;
}

export const getOptionChanges = (prev, options) => {
    const changes = [];
    if (options.displayCurrency !== prev.displayCurrency) {
        changes.push("displayCurrency");
    }

    catalogueFilterKeys.forEach(k => {
        if (filterValue(options[k]) !== filterValue(prev[k])) {
            changes.push(k);
        }
    });

    return changes;
};

export const getBodyChanges = (prev, body) => {
    if (!prev) {
        return Object.keys(body);
    }
    const changes = [];
    const keys1 = Object.keys(body);
    const keys2 = Object.keys(prev);
    const keys = [ ...keys1, ...keys2 ].filter((key, inx, self) => self.indexOf(key) === inx);

    keys.forEach(key => {
        if (filterValue(body[key]) !== filterValue(prev[key])) {
            changes.push(key);
        }
    });
    
    return changes;
};

export const hasResettingChange = (changes) => {
    return !!changes.find(key => key !== "start" && 
                                    key !== "limit" &&
                                    catalogueFilterKeys.indexOf(key) !== -1);
};

export const optionsDidChange = (prev, options) => {
    const changes = getOptionChanges(prev, options);
    return changes.length > 0;
};


const prepareRequestBody = (options) => {
    const body = {};
    const { region, currency, displayCurrency, 
            perPage = PER_PAGE, page = 0 } = options;

    catalogueFilterKeys.forEach(k => {
        if (options[k]) {
            body[k] = options[k];
        }
    });

    if (region && region.toLowerCase() === "rest") {
        body['region'] = catalogueRestCodes;
    }
    else if (region && region.toLowerCase() === "euro") {
        body['region'] = catalogueEuroCodes;
    }
    else if (region && region.toLowerCase() === "all") {
        delete body['region'];
    }
    else if (body['region']) {
        body['region'] = body['region'].toUpperCase();
    }

    if (currency && currency.toLowerCase() === "rest") {
        body['currency'] = catalogueRestCurrencies;
    }
    else if (currency && currency.toLowerCase() === "all") {
        delete body['currency'];
    }
    else if (body['currency']) {
        body['currency'] = body['currency'].toUpperCase();
    }

    if (displayCurrency) {
        if (!body['currency']) {
            body['currency'] = "USD,GBP,EUR";
        }
    }

    if (!body['query'] && !body['sort_by']) {
        body['sort_by'] = "random";
        body['sort_dir'] = "asc";
    }

    if (!body['stage']) {
        body['stage'] = "live"; //process.env.REACT_APP_ENV;
    }
    if (body['limit'] === undefined) {
        body['limit'] = perPage;
    }
    if (body['start'] === undefined) {
        body['start'] = page * perPage;
    }

    return body;
}

function productPriceSorter(dir) {

    return function(a, b) {
        const p1 = a.price_usd;//a.sale_price || a.price;
        const p2 = b.sale_price || b.price;
        if (p1 === p2) {
            return 0;
        }
        if (dir === "asc") {
            return p1 < p2 ? -1 : 1;
        }
        else {
            return p1 < p2 ? 1 : -1;
        }
    }
}

export const catalogueLoader = async (options, callbacks = {}) => {

    const body = prepareRequestBody(options);

    const storeState = store.getState();
    let misc, prevBody, prevDisplayCurrency;
    let { displayCurrency, page = 0, perPage = PER_PAGE,
            setName = null, append = false, force = false,
            withSaved = false, withReactions = false, 
            reactionsUserId = user.id() } = options;
    const exSet = storeState.catalogue.sets.products[setName];
    const commissionRates = user.loggedIn() ? 
                                user.isOnly("User") ? null : getCommissionRates() :
                                null;

    if (setName) {
        misc = storeState.catalogue.ui.products.misc;
        prevBody = misc[setName]?.filters || null;
        prevDisplayCurrency = misc[setName]?.displayCurrency;
    }
    else {
        prevBody = storeState.catalogue.ui.products.filter;
    }

    if (displayCurrency) {
        body['displayCurrency'] = displayCurrency;
    }

    if (process.env.REACT_APP_SKIP_API_CACHE) {
        body['skip_backend_cache'] = true;
    }

    const bodyChanges = prevBody && Object.keys(prevBody).length > 0 ? 
                            getBodyChanges({ ...prevBody }, { ...body }) :
                            [];
    if (prevBody && Object.keys(prevBody).length > 0 && 
        bodyChanges.length === 0 && exSet && exSet.length > 0 && !force) {
        return;
    }

    batch(() => {
        if (hasResettingChange(bodyChanges)) {
            body['start'] = 0;
            page = 0;
            append = false;
            if (setName) {
                store.dispatch(sets.products.set({ name: setName, value: [] }));
            }
        }

        if (setName) {
            misc = Object.assign(
                {}, 
                misc, 
                { 
                    [setName]: { 
                        ...misc[setName], 
                        loading: true,
                }});
            store.dispatch(ui.products.setMisc(misc));
        }
        else {
            store.dispatch(ui.products.loading(true));
        }
    });

    let { products, count } = await api.unauth.post("/catalogue/search", { body });


    if (callbacks.prefetchImages) {
        const pfimgRes = callbacks.prefetchImages(products);
        if (pfimgRes instanceof Promise) {
            await pfimgRes;
        }
    }

    const pids = products.map(p => p.product_web_id);
    let puids = products.map(p => p.uid);
    const hasMore = puids.length < perPage ? false : true;

    if (withSaved) {
        if (user.loggedIn() && pids.length > 0) {
            const saved = await api.userSavedCatalogueProduct.list({
                                        where: {
                                            productCatalogueId: { _in: pids }
                                        }
                                    }, "productCatalogueId")
                                    .then(list => list.map(i => i.productCatalogueId));
            products.forEach(p => {
                p.saved = saved.indexOf(p.product_web_id) !== -1;
            })
        }
    }

    if (withReactions) {
        if (user.loggedIn() && pids.length > 0) {
            const cpsWhere = { productWebId: { _in: pids }};
            const cpsGraph = "id productWebId";
            const cps = await api.catalogueProduct.list({ where: cpsWhere }, cpsGraph);
            const cpsIds = cps.map(p => p.id);
            const rWhere = { catalogueProductId: { _in: cpsIds }, userId: { _eq: reactionsUserId }};
            const rGraph = "catalogueProductId reaction";
            const rs = await api.userReaction.list({ where: rWhere }, rGraph);
            products.forEach(p => {
                const pwid = p.product_web_id;
                const cpid = cps.find(p => p.productWebId === pwid)?.id;
                const reaction = rs.find(r => r.catalogueProductId === cpid)?.reaction;
                p.reaction = reaction || null;
            })
        }
    }

    if (displayCurrency) {
        const exchangeRates = await getExchangeRates();
        products = products.map(p => convertCurrency(p, displayCurrency, exchangeRates));

        // if (body['sort_by'] === "price") {
        //     products = products.sort(productPriceSorter(body['sort_dir']));
        //     puids = products.map(p => p.uid);
        // }
    }

    if (commissionRates) {
        products = products.map(p => applyCommissionRates(p, commissionRates));
    }
    
    batch(() => {
        if (setName) {
            misc = Object.assign(
                {}, 
                misc, 
                { 
                    [setName]: { 
                        ...misc[setName], 
                        loading: false, 
                        loaded: true,
                        count,
                        displayCurrency,
                        filters: body,
                        page,
                        hasMore
                    }
                }
            );
            store.dispatch(ui.products.setMisc(misc));
        }
        else {
            store.dispatch(ui.products.filter(body));
            store.dispatch(ui.products.page(page));
            store.dispatch(ui.products.loading(false));
            store.dispatch(ui.products.count(count));
            store.dispatch(ui.products.hasMore(hasMore));
        }

        //if (prevDisplayCurrency !== displayCurrency) {
            store.dispatch(data.products.merge(products));
        //}
        //else {
        //    store.dispatch(data.products.append(products));
        //}

        if (append) {
            const exSet = storeState.catalogue.sets.products[setName];
            if (!exSet) {
                store.dispatch(sets.products.set({ name: setName, value: puids }));    
            }
            else {
                store.dispatch(sets.products.append({ name: setName, value: puids }));
            }
        }
        else {
            store.dispatch(sets.products.set({ name: setName, value: puids }));
        }
    })

    return { products, count };
}

export const setManualProducts = (setName, products) => {
    const storeState = store.getState();
    let misc = storeState.catalogue.ui.products.misc;
    misc = Object.assign(
        {}, 
        misc, 
        { 
            [setName]: { 
                ...misc[setName], 
                loading: false, 
                loaded: true,
                count: products.length,
                filters: {},
                page: 0
            }
        }
    );
    batch(() => {
        store.dispatch(data.products.append(products));
        store.dispatch(sets.products.set({ name: setName, value: products.map(p => p.uid) }));    
        store.dispatch(ui.products.setMisc(misc));
    });
};

export const getProductsBySet = (setName) => {
    const state = store.getState();
    const products = state.catalogue.data.products;
    const ids = state.catalogue.sets.products[ setName ] || [];
    return ids.map(id => products.find(p => p.uid === id));
}

export const clearSet = (setName) => {
    const storeState = store.getState();
    const misc = { ...storeState.catalogue.ui.products.misc };
    delete misc[setName];

    batch(() => {
        store.dispatch(sets.products.unset(setName));
        store.dispatch(ui.products.setMisc(misc));
    });
}


export const loadFacets = async () => {

    const state = store.getState();

    if (state.catalogue.data.prices.length !== 0) {
        return;
    }

    const defaultRetailers = await getRetailers();
    const commissionRates = !user.isOnly("User") ? 
                                state.catalogue.data.commissionRates || null : null;

    batch(() => {
        store.dispatch(ui.retailers.loading(true));
        //store.dispatch(ui.brands.loading(true));
        store.dispatch(ui.prices.loading(true));
    });

    const res = await api.unauth.post("/catalogue/search", {
        body: {
            stage: "live",
            facetsOnly: true,
            facets: {
                retailer: {
                    size: 100,
                    sort: "bucket"
                }
            }
        }
    });

    const retailerBuckets = res.retailer?.buckets || [];

    const retailers = retailerBuckets.map(b => b.value).map(id => {
        //if (id === "selfridges") {
        //    return null;
        //}
        const r = defaultRetailers.find(r => r.id === id);
        if (r) {
            if (r.visible === false) {
                return null;
            }
            const retailer = { ...r };
            if (user.loggedIn() && commissionRates) {
                retailer.commissionRate = getRateForRetailer(id, commissionRates);
            }
            return retailer;
        }
    }).filter(r => !!r);

    //const brands = res.brand.buckets.filter(b => b.count > 1000).map(b => b.value);
    const prices = [
        100,
        200,
        300,
        400,
        500,
        1000,
        5000
    ];

    batch(() => {
        store.dispatch(data.retailers.set(retailers));
        //store.dispatch(data.brands.set(brands));
        store.dispatch(data.prices.set(prices));
        store.dispatch(ui.retailers.loading(false));
        //store.dispatch(ui.brands.loading(false));
        store.dispatch(ui.prices.loading(false));
        store.dispatch(data.facetsLoaded.set(true));
    });
};