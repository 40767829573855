
export default function getDefaultBackgroundSize(layout = {}, img, template) {

    let row = (layout.row || 1) - 1,
        col = (layout.col || 1) - 1;

    if (template) {
        let min = null, max = null, diff;
        template.forEach(l => {
            min === null && (min = max = l.row)
            min > l.row && (min = l.row);
            max < l.row && (max = l.row);
            diff = max - min;
        });
        if (row > diff) {
            row = diff;
        }
    }

    let backSize = img.vertical ? 
        (120 + (row * 50) - (col * 50)) :
        100;

    backSize < 100 && (backSize = 100);

    return backSize;
}