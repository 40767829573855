
const retailerMap = {
    "net-a-porter": "NET-A-PORTER",
    "modaoperandi": "MODA OPERANDI",
    "browns": "BROWNS FASHION",
    "farfetch": "FARFETCH",
    "ferragamo": "FERRAGAMO",
    "ssense": "SSENSE",
    "matchesfashion": "MATCHESFASHION",
    "mytheresa": "MYTHERESA",
    "chloe": "CHLOE.COM",
    "intermix": "INTERMIX",
    "luisaviaroma": "LVR",
    "neous": "NEOUS",
    "harveynichols": "HN",
    "rl": "RL",
    "jimmychoo": "JIMMY CHOO",
    "etro": "ETRO",
    "rogervivier": "ROGER VIVIER",
    "shopbop": "SHOPBOP",
    "webster": "THE WEBSTER",
    "neiman": "NEIMAN MARCUS",
    "bergdorf": "BERGDORF GOODMAN",
    "forward": "FWRD",
    "tods": "TOD's",
    "saks": "SAKS",
    "gucci": "GUCCI",
    "selfridges": "SELFRIDGES",
    "sturm": "DR. BARBARA STURM",
    "altuzarra": "Altuzarra",
    "wickstead": "Emilia Wickstead",
    "miumiu": "MiuMiu",
    "dg": "Dolce Gabbana",
    "prada": "Prada",
};

export default function getRetailerName(url) {
    if (!url) {
        return null;
    }

    if (retailerMap[url]) {
        return retailerMap[url];
    }

    try {
        url = new URL(url.toLowerCase());
    }
    catch (err) {
        return null;
    }

    if (url.hostname.indexOf("ralphlauren.") !== -1) {
        return "RL";
    }

    if (url.hostname.indexOf("drsturm") !== -1) {
        return "DR. BARBARA STURM";
    }

    switch (url.hostname) {
        case "net-a-porter.com":
        case "www.net-a-porter.com": {
            return "NET-A-PORTER";
        }
        case "modaoperandi.com":
        case "www.modaoperandi.com": {
            return "MODA OPERANDI";
        }
        case "brownsfashion.com":
        case "www.brownsfashion.com": {
            return "BROWNS FASHION";
        }
        case "farfetch.com":
        case "www.farfetch.com": {
            return "FARFETCH";
        }
        case "ssense.com":
        case "www.ssense.com": {
            return "SSENSE";
        }
        case "matchesfashion.com":
        case "www.matchesfashion.com": {
            return "MATCHESFASHION";
        }
        case "mytheresa.com":
        case "www.mytheresa.com": {
            return "MYTHERESA";
        }
        case "chloe.com":
        case "www.chloe.com": {
            return "CHLOE.COM";
        }
        case "intermixonline.com":
        case "www.intermixonline.com": {
            return "INTERMIX";
        }
        case "luisaviaroma.com":
        case "www.luisaviaroma.com": {
            return "LVR";
        }
        case "neous.co.uk":
        case "www.neous.co.uk": {
            return "NEOUS";
        }
        case "harveynichols.com":
        case "www.harveynichols.com": {
            return "HN";
        }
        case "etro.com":
        case "www.etro.com": {
            return "ETRO";
        }
        case "jimmychoo.com":
        case "www.jimmychoo.com":
        case "row.jimmychoo.com": {
            return "JIMMY CHOO";
        }
        case "rogervivier.com":
        case "www.rogervivier.com": {
            return "ROGER VIVIER";
        }

        case "shopbop.com":
        case "www.shopbop.com": {
            return "SHOPBOP"
        }

        case "thewebster.us":
        case "www.thewebster.us": {
            return "THE WEBSTER"
        }

        case "neimanmarcus.com":
        case "www.neimanmarcus.com": {
            return "NEIMAN MARCUS"
        }

        case "bergdorfgoodman.com":
        case "www.bergdorfgoodman.com": {
            return "BERGDORF GOODMAN"
        }

        case "fwrd.com":
        case "www.fwrd.com": {
            return "FWRD"
        }

        case "tods.com":
        case "www.tods.com": {
            return "TOD's"
        }

        case "saksfifthavenue.com":
        case "www.saksfifthavenue.com": {
            return "SAKS"
        }

        case "gucci.com":
        case "www.gucci.com": {
            return "GUCCI"
        }

        case "selfridges.com":
        case "www.selfridges.com": {
            return "SELFRIDGES"
        }

        case "ralphlauren.co.uk":
        case "www.ralphlauren.co.uk": {
            return "RL"
        }

        case "ferragamo.com":
        case "www.ferragamo.com": {
            return "FERRAGAMO"
        }

        case "altuzarra.com":
        case "www.altuzarra.com": {
            return "ALTUZARRA"
        }

        case "emiliawickstead.com":
        case "www.emiliawickstead.com": {
            return "EMILIA WICKSTEAD"
        }

        case "miumiu.com":
        case "www.miumiu.com": {
            return "MIUMIU"
        }

        case "dolcegabbana.com":
        case "www.dolcegabbana.com": {
            return "DOLCE GABBANA"
        }

        case "prada.com":
        case "www.prada.com": {
            return "PRADA"
        }

        default: {
            return url.hostname.toUpperCase();
        }
    }
}