import getDefaultBackgroundSize from "./getDefaultBackgroundSize"

export default function getBackgroundSize(layout = {}, img, template) {

    let backSize = getDefaultBackgroundSize(layout, img, template);
    backSize += (layout.zoom || 0);

    return backSize;
}

export function backSize2scale(bs) {
    if (!bs) {
        return 1;
    }

    return (bs / 100) + 1;
}